import ApplicationController from './application_controller'

export default class extends ApplicationController {
  /**
   * Insert Google maps in the head tag
   */
  initialize () {
    const GMAPS_API =
      'https://maps.googleapis.com/maps/api/js?libraries=places&key=AIzaSyDDtnO2DTXPlZfYkaKaGiiioBDojprD9GY&callback=initMap'

    let script = document.createElement('script')
    script.src = GMAPS_API

    document.head.appendChild(script)
  }
}
