import { Controller } from "@hotwired/stimulus"
import EventBus from '../event-bus'

export default class DropdownListController extends Controller {
  static targets = ['input', 'button']
  static values = { from: String }

  connect() {
    this.id = this.generateRandomId()
    this.onDropDownToggle = this.onDropDownToggle.bind(this)
    this.onClickOutside = this.onClickOutside.bind(this)

    EventBus.on('dropdown:toggle', this.onDropDownToggle)
    document.addEventListener('click', this.onClickOutside)
  }

  disconnect() {
    document.removeEventListener('click', this.onClickOutside)
    EventBus.off('dropdown:toggle', this.onDropDownToggle)
  }

  generateRandomId() {
    return Math.floor(Math.random() * 1000000) + 1;
  }

  onDropDownToggle(event) {
    const payload = event.detail
    console.log(payload.id)
    if (payload.id !== this.id && payload.isOpen) {
      this.inputTarget.classList.add('hidden')
    }
  }

  onClickOutside(event) {
    if (!this.inputTarget.contains(event.target) && !this.buttonTarget.contains(event.target)) {
      this.inputTarget.classList.add('hidden')
    }
  }

  toggleColumnSelector (event) {
    let $columnOptions = this.inputTarget
    const $modifyColumnsButton = this.buttonTarget

    const rect = $modifyColumnsButton.getBoundingClientRect();
    $columnOptions.style.left = (rect.left - 1.8*rect.height) + 'px';
    $columnOptions.style.top = (rect.top + rect.height) + 'px';

    if (!$columnOptions.contains(event.target) && !$modifyColumnsButton.contains(event.target)) {
      $columnOptions.classList.add('hidden')
    }

    $columnOptions.classList.toggle('hidden')

    EventBus.emit('dropdown:toggle', {
      isOpen: !$columnOptions.classList.contains('hidden'),
      id: this.id
    })
  }
}
